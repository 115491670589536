import { apiGetSecuritysRule } from '@/resource'

export default {

  data() {
    return {
      passwordVisible: false,
      verifyPasswordVisible: false,
    }
  },

  methods: {

    getSecuritysRule() {

      apiGetSecuritysRule({ functionCode: 'updatePassword' }).then(res => {
        if (res.data.data.ruleContent) {

          this.day = 'T + ' + res.data.data.ruleContent.withdraw.limitTime;
          this.visible = true;
        } else {
          this.confirmPw();
        }
      });
    },

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    toggleVerifyPasswordVisibility() {
      this.verifyPasswordVisible = !this.verifyPasswordVisible;
    }
  },
}
