<template>
  <el-form :model="form" ref="form" :rules="rules" :hide-required-asterisk="isForceChangePassword">
        <div class="form-list">
      <el-form-item
        :class="{ 'code-sended': isClickSendEmail }"
        :label="$t('common.field.enterVerificationCode')"
        prop="code"
      >
        <div class="verification-code-container" v-if="!isForceChangePassword">
          <div class="verification-code-item">
            <el-input v-model="form.code" id="verificationCode" class="plain-input" data-testid="code" />
          </div>
          <div class="verification-code-item">
            <el-button
              class="el-button btn-blue"
              :disabled="isSendEmail"
              @click="sendEmail()"
              data-testid="code-button"
            >
              {{ isClickSendEmail ? $t('common.button.resendCode') : $t('common.button.sendCode') }}
              {{ displayTime > 0 ? `(${displayTime})` : '' }}
            </el-button>
          </div>
        </div>
        <div class="login-verification-code-container" v-else>
          <div class="verification-code-item">
            <el-input v-model="form.code" id="verificationCode" :placeholder="$t('common.field.enterCode')" class="plain-input" data-testid="code" />
          </div>
          <div class="verification-code-item">
            <el-button
              class="send-btn"
              :disabled="isSendEmail"
              @click="sendEmail()"
              data-testid="code-button"
            >
              {{ isClickSendEmail && displayTime > 0 ? `${displayTime}s` : $t('common.button.requestCode') }}
            </el-button>
          </div>
        </div>
      </el-form-item>
    </div>
    <div class="text-red " v-if="errorCode !== 0">
      {{ $t(`responseMsg.${errorCode}`) }}
    </div>
    <div class="text-primary" v-if="isClickSendEmail && !isForceChangePassword">
      {{
        $t('withdraw.otherMethod.verificationCode', {
          email: getEmail,
          supportEmail: getSupportEmail
        })
      }}
    </div>
  </el-form>
</template>

<script>
import { apiSendEmailVerificationCode } from '@/resource';

export default {
  name: 'VerificationCode',
  props: {
    namespace: String,
    token: {
      type: String,
      default: null
    },
    maskedEmail: {
      type: String,
      default: null
    },
    callbackRegulator: {
      type: String,
      default: null
    },
    isForceChangePassword: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form: {
        code: ''
      },
      rules: {
        code: [
          {
            required: true,
            message: this.$t('common.formValidation.verCodeReq'),
            trigger: 'blur'
          }
        ]
      },
      isSendEmail: false,
      isClickSendEmail: false,
      secondTime: 60,
      displayTime: 0,
      timeInterval: null,
      errorCode: 0,
      txId: ''
    };
  },
  computed: {
    getEmail() {
      return this.maskedEmail ? this.maskedEmail : this.$store.state.common.emailMasked;
    },
    getSupportEmail() {
      return this.callbackRegulator ? this.$config.info.getEmail(this.callbackRegulator) : this.GLOBAL_CONTACT_EMAIL;
    }
  },
  methods: {
    sendEmail() {
      apiSendEmailVerificationCode({ namespace: this.namespace, token: this.token }).then(resp => {
        this.isSendEmail = true;
        this.isClickSendEmail = true;
        this.startTimer();

        switch (resp.data.code) {
          case 0:
            this.txId = resp.data.data.txId;
            break;
          case 530:
            this.$router.push({ name: 'login' });
            break;
          default:
            this.errorCode = resp.data.code;
            break;
        }
      });
    },
    startTimer() {
      this.displayTime = this.secondTime;
      this.timeInterval = setInterval(this.countDown, 1000);
    },
    countDown() {
      this.displayTime--;
      if (this.displayTime === 0) this.stopTimer();
    },
    stopTimer() {
      clearInterval(this.timeInterval);
      this.isSendEmail = false;
    },
    getFormDataOfChild() {
      return {
        txId: this.txId,
        code: this.form.code
      };
    },
    submitForm() {
      return this.$refs['form'].validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.verification-code-container {
  display: flex;
  justify-content: space-between;
  .verification-code-item + .verification-code-item {
    margin-left: 8px;
  }
  /deep/ .btn-blue {
  margin-top: 0 !important;
}
}

.login-verification-code-container {
  display: flex;
  justify-content: space-between;
  .verification-code-item + .verification-code-item {
    margin-left: 20px;
  }
  .send-btn {
    width: 130px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #BBBABA;
    background: #EBF4FF;
    color: #004ABC;
    padding: 0;
    text-transform: capitalize;
  }
}

/deep/ .btn-blue {
  margin-top: 0 !important;
}

@media (max-width: 550px) {
  .verification-code-container {
    flex-direction: column;
    text-align: center;

    .verification-code-item + .verification-code-item {
      margin-left: 0px !important;
      margin-top: 16px;
    }
    .verification-code-item {
      .el-button {
        width: 100%;
      }
    }
  }
}
</style>
