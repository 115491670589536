<template>
  <el-dialog class="withdraw_dialog" :title="$t('changePw.field.title')" top="0" :show-close="false" :visible.sync="visible" append-to-body width="330px" center>
    <div class="word_break">
      {{ $t('changePw.field.withdrawText', { day: this.day }) }}
    </div>
    <div slot="footer">
      <el-button @click="confirm" class="ok-btn">{{
        $t('common.button.confirm')
      }}</el-button>
      <el-button @click="close" class="cancel-btn">{{ $t('common.button.cancel') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'LimitWithdrawDialog',
  props: ['visible', 'day'],
  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
        this.$emit('changeVisible', val);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    close() {
      this.$emit('changeVisible', false);
    },
    confirm() {
      this.$emit('changeVisible', false);
      this.$emit('confirmPw');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/loginForm.scss';
.withdraw_dialog {
  /deep/ .el-dialog__header {
    padding: 40px 30px 0 30px;
    color: $pug-black !important;
    font-weight: 900;
    text-align: center;
    font-size: 20px;
  }

  /deep/ .el-dialog__body {
    padding: 0 30px;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  /deep/ .el-dialog__footer {
    padding: 0 30px 40px 30px;
  }
  .word_break {
    word-break: normal;
    font-size: 14px;
    color: $pug-black;
    line-height: 19px;
  }
  .cancel-btn {
    margin-top: 20px;
  }
}
</style>
