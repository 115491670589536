<template>
  <StaticPagePug>
    <div class="form-wrapper">
      <div id="force-changePassword">
        <div class="title">{{ $t('resetPassword.header') }}</div>
          <el-form
            label-position="top"
            :model="ruleChangePasswordForm"
            :rules="rulesChangePassword"
            hide-required-asterisk
            ref="ruleChangePasswordForm"
          >
            <el-form-item :label="$t('changePw.field.newPw')" prop="pass">
              <el-input :type="passwordVisible ? 'text' : 'password'" v-model="ruleChangePasswordForm.pass" autocomplete="off" class="suffix-input" :placeholder="$t('changePw.field.newPw')">
                <template slot="suffix">
                  <img src="@/assets/images/pug/eye-line.png" alt="view" @click="togglePasswordVisibility" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('changePw.field.confirmPw')" prop="checkPass" class="confirmPassword">
              <el-input :type="verifyPasswordVisible ? 'text' : 'password'" v-model="ruleChangePasswordForm.checkPass" autocomplete="off" class="suffix-input" :placeholder="$t('changePw.field.newPw')">
                <template slot="suffix">
                  <img src="@/assets/images/pug/eye-line.png" alt="view" @click="toggleVerifyPasswordVisibility" />
                </template>
              </el-input>
            </el-form-item>
            <VerificationCode
              ref="verificationCode"
              namespace="CP_FORCE_UPDATE_PASSWORD"
              :token="token"
              :maskedEmail="maskedEmail"
              :callbackRegulator="callbackRegulator"
              isForceChangePassword
            ></VerificationCode>
            <p class="rule-tip">{{ $t('common.formValidation.newPwFormat') }}</p>
            <el-form-item class="btn-item">
              <el-button class="ok-btn" @click="submitForm()" data-testid="changePw">
                {{ $t('common.button.submit') }}
              </el-button>
            </el-form-item>
          </el-form>
      </div>
    </div>
    <LimitWithdrawDialog
      :visible.sync="visible"
      :day="day"
      @changeVisible="changeVisible"
      @confirmPw="confirmPw"
    ></LimitWithdrawDialog>
  </StaticPagePug>
</template>

<script>
import { md5 } from '@/util/encrypt';
import Result from '@/components/Result';
import StaticPagePug from '@/components/template/staticPage/StaticPagePug';
import VerificationCode from '@/components/withdraw/VerificationCode';
import {
  apiSendEmailVerificationCode,
  apiCheckPassword,
  apiForceUpdatePassword,
  apiGetSecuritysRule
} from '@/resource';
import LimitWithdrawDialog from '@/components/dialog/withdraw.vue';
import withdrawMixin from '@/mixins/withdrawl/withdrawLimit';

export default {
  name: 'forceChangePassword',
  components: { StaticPagePug, Result, VerificationCode, LimitWithdrawDialog },
  mixins: [withdrawMixin],

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*.()])(?=.*[0-9])(?=.*[a-z]).{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.newPwReqErr')));
        } else {
          callback();
        }
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleChangePasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };
    return {
      day: '',
      visible: false,
      formFlag: true,
      ruleChangePasswordForm: {
        pass: '',
        checkPass: ''
      },
      rulesChangePassword: {
        pass: [{ validator: validatePass, trigger: ['blur', 'change'] }],
        checkPass: [{ validator: validateCheckPass, trigger: ['blur', 'change'] }]
      },
      token: null,
      callbackRegulator: null,
      maskedEmail: null
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    this.callbackRegulator = this.$route.params.callbackRegulator;
    this.maskedEmail = this.$route.params.maskedEmail;
    if (!this.token) this.goToLogin();
  },
  methods: {
    changeVisible(val) {
      this.visible = val;
    },

    confirmPw() {
      this.forceUpdatePassword();
    },
    submitForm() {
      const [getPasswordForm, getVerifyCodeComponent] = [
        this.$refs.ruleChangePasswordForm,
        this.$refs.verificationCode
      ];
      const [verifyPasswordMethod, verifyCodeMethod] = [
        getPasswordForm.validate(),
        getVerifyCodeComponent.submitForm()
      ];

      Promise.all([verifyPasswordMethod, verifyCodeMethod]).then(async valid => {
        if (valid) {
          await this.getSecuritysRule();
        } else return false;
      });
    },
    forceUpdatePassword() {
      const getVerifyCodeComponent = this.$refs.verificationCode;

      apiForceUpdatePassword({
        pass: md5(this.ruleChangePasswordForm.pass),
        checkPass: md5(this.ruleChangePasswordForm.checkPass),
        token: this.token,
        ...getVerifyCodeComponent.getFormDataOfChild()
      }).then(resp => {
        if (resp.data.code === 0 || resp.data.code === 530) {
          this.goToLogin();
        } else if (resp.data.code === 647) {
          this.$message({
            message: this.$t('responseMsg.647'),
            type: 'error'
          });
        }
      });
    },
    goToLogin() {
      return this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/loginForm.scss';
.rule-tip {
  color: $pug-black;
  font-size: 14px;
  margin-top: 30px;
}

.confirmPassword {
  padding-bottom: 14px;
}
</style>
